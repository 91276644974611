import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AsciinemaEmbed = makeShortcode("AsciinemaEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Sincode is a tool that can be used to brute-force caesar, rot13, discard every `}<inlineCode parentName="p">{`n`}</inlineCode>{` and other simple text ciphers via brute-force. It works by performing several "mutations" on the input file and then determining which ones are the most likely to be English based on character frequencies and known words. `}</p>
    <h2>{`Demo`}</h2>
    <AsciinemaEmbed id="319817" width="700px" height="300px" mdxType="AsciinemaEmbed" />
    <h2>{`Source`}</h2>
    <p>{`Source code for sincode is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/sincode"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      